import React, { useState, useRef, useEffect } from 'react';
import Header from './components/Header';
import TraitSelection from './components/TraitSelection';
import ArtDisplay from './components/ArtDisplay';
import ActionsPanel from './components/ActionsPanel';
import Footer from './components/Footer';
import RulesModal from './components/RulesModal';
import metadata from './Metadata.json';
import './App.css';
import './mobile.css';
import traits, { rules } from './traits';
import { getImagePath } from './utils';

function App() {
  // Initialize theme when component mounts
  useEffect(() => {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme === 'light') {
      document.body.classList.add('light-theme');
    }
  }, []);

  const defaultTraits = {
    background: 'None',
    fur: 'Midnight', 
    clothing: 'Crafting Apron', 
    eyes: 'None',
    head: 'Chef Hat',
    mouth: 'None',
    funAddOns: 'None', 
    curated: 'None', 
  };

  const baseLayersOrder = ["background", "fur", "clothing", "eyes", "head", "mouth", "funAddOns", "curated"];

  const [selectedTraits, setSelectedTraits] = useState(defaultTraits);
  const [tokenID, setTokenID] = useState(null);
  const [matchType, setMatchType] = useState(null);
  const [showRules, setShowRules] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);
  const artRef = useRef(null);

  useEffect(() => {
    const checkForMatch = () => {
      // If any "Fun Add-On" trait is selected, there should be no match
      if (selectedTraits.funAddOns !== "None") {
        setTokenID(null);
        setMatchType(null);
        return;
      }

      // If a "Curated" trait is selected, match based only on the "Curated Canine" trait in metadata
      if (selectedTraits.curated !== "None") {
        const matchedToken = metadata.find((item) => {
          const curatedTrait = item.attributes.find(attr => attr.trait_type === "Curated Canine");
          return curatedTrait && curatedTrait.value === selectedTraits.curated;
        });

        if (matchedToken) {
          setTokenID(matchedToken.JsonTokenID);
          setMatchType('exact');
        } else {
          setTokenID(null);
          setMatchType(null);
        }
        return;
      }

      // If no "Curated" trait is selected, match based on all other traits
      const currentTraits = {
        Background: selectedTraits.background,
        Fur: selectedTraits.fur,
        Clothing: selectedTraits.clothing,
        Eyes: selectedTraits.eyes,
        Head: selectedTraits.head,
        Mouth: selectedTraits.mouth,
      };

      const matchedToken = metadata.find((item) => {
        const itemTraits = item.attributes.reduce((acc, attribute) => {
          acc[attribute.trait_type] = attribute.value;
          return acc;
        }, {});

        const isExactMatch = Object.keys(currentTraits).every(
          (key) => currentTraits[key] === itemTraits[key]
        );

        const isTwinMatch = Object.keys(currentTraits).every(
          (key) => key === 'Background' || currentTraits[key] === itemTraits[key]
        );

        if (isExactMatch) {
          setMatchType('exact');
          return item;
        } else if (isTwinMatch) {
          setMatchType('twin');
          return item;
        }
        return null;
      });

      if (matchedToken) {
        setTokenID(matchedToken.JsonTokenID);
      } else {
        setTokenID(null);
        setMatchType(null);
      }
    };

    checkForMatch();
  }, [selectedTraits]);

  const handleTraitChange = (trait, value) => {
    setSelectedTraits((prev) => {
      const updatedTraits = { ...prev, [trait]: value };

      if (trait === "curated" && value !== "None") {
        Object.keys(updatedTraits).forEach((key) => {
          if (key !== "curated") {
            updatedTraits[key] = "None"; 
          }
        });
        updatedTraits.fur = "Midnight";  
        updatedTraits.clothing = "Naked"; 
        updatedTraits.head = "None";      
      } else if (trait !== "curated" && prev.curated !== "None") {
        updatedTraits["curated"] = "None";
      }

      if (trait === "fur" && prev.funAddOns === "GM Mug") {
        updatedTraits["funAddOns"] = "GM Mug";
      }

      return updatedTraits;
    });
  };

  // Function to reset the traits to the default state
  const resetTraits = () => {
    setSelectedTraits(defaultTraits);
  };

  const randomizeTraits = () => {
    const randomizedTraits = Object.keys(selectedTraits).reduce((acc, traitType) => {
      const options = traits[traitType];

      if (traitType === "funAddOns" || traitType === "curated") {
        acc[traitType] = "None";
      } else {
        let validOptions = options.filter((option) => {
          return Object.keys(acc).every(
            (selectedType) =>
              (rules[option] ? !rules[option].includes(acc[selectedType]) : true) &&
              (rules[acc[selectedType]] ? !rules[acc[selectedType]].includes(option) : true)
          );
        });

        const randomTrait = validOptions[Math.floor(Math.random() * validOptions.length)];
        acc[traitType] = randomTrait;
      }

      return acc;
    }, {});

    setSelectedTraits(randomizedTraits);
  };

  // Function to handle Token ID input and set traits based on metadata
  const handleTokenIDChange = (tokenId) => {
    if (tokenId < 1 || tokenId > 420) return; 

    const matchedToken = metadata.find((item) => item.JsonTokenID === tokenId);

    if (matchedToken) {
      const curatedTrait = matchedToken.attributes.find(attr => attr.trait_type === "Curated Canine");

      if (curatedTrait) {
        setSelectedTraits({
          background: 'None',
          fur: 'Midnight',
          clothing: 'Naked', 
          eyes: 'None',
          head: 'None',      
          mouth: 'None',
          funAddOns: 'None',
          curated: curatedTrait.value,
        });
      } else {
        const newTraits = matchedToken.attributes.reduce((acc, attribute) => {
          const traitType = attribute.trait_type.toLowerCase();
          acc[traitType] = attribute.value;
          return acc;
        }, {});

        setSelectedTraits((prev) => ({
          ...prev,
          ...newTraits,
          curated: 'None', 
          funAddOns: 'None',
        }));
      }
    }
  };

  const downloadImage = () => {
    const canvas = document.createElement('canvas');
    canvas.width = 800;
    canvas.height = 800;
    const ctx = canvas.getContext('2d');

    const layersOrder = selectedTraits.curated !== "None" ? ["curated"] : baseLayersOrder;

    const loadImage = (traitType, trait) => {
      return new Promise((resolve) => {
        if (trait === 'None') {
          resolve();
          return;
        }
        
        const imgPath = getImagePath(traitType, trait, selectedTraits);
        if (!imgPath) {
          resolve();
          return;
        }
        
        const img = new Image();
        img.src = imgPath;
        img.onload = () => {
          ctx.drawImage(img, 0, 0, 800, 800);
          resolve();
        };
        img.onerror = () => {
          console.error(`Failed to load image: ${imgPath}`);
          resolve();
        };
      });
    };

    const loadAllImages = async () => {
      for (const layer of layersOrder) {
        await loadImage(layer, selectedTraits[layer]);
      }
      
      const link = document.createElement('a');
      link.download = 'nft-art.png';
      link.href = canvas.toDataURL('image/png');
      link.click();
    };
    
    loadAllImages();
  };

  const copyToClipboard = () => {
    const canvas = document.createElement('canvas');
    canvas.width = 800;
    canvas.height = 800;
    const ctx = canvas.getContext('2d');

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    const layersOrder = selectedTraits.curated !== "None" ? ["curated"] : baseLayersOrder; 

    const loadAndDrawLayers = async () => {
      for (const traitType of layersOrder) {
        const trait = selectedTraits[traitType];
        if (!trait || trait === 'None') continue;
        
        const imgPath = getImagePath(traitType, trait, selectedTraits);
        if (!imgPath) continue;
        
        await new Promise((resolve) => {
          const img = new Image();
          img.src = imgPath;
          img.onload = () => {
            ctx.drawImage(img, 0, 0, 800, 800);
            resolve();
          };
          img.onerror = () => {
            console.error(`Failed to load image: ${imgPath}`);
            resolve();
          };
        });
      }
      
      canvas.toBlob((blob) => {
        if (navigator.clipboard && window.ClipboardItem) {
          const item = new window.ClipboardItem({ 'image/png': blob });
          navigator.clipboard.write([item]).then(() => {
            setCopySuccess(true); 
            setTimeout(() => setCopySuccess(false), 2000); 
          }).catch((err) => {
            console.error('Failed to copy image to clipboard:', err);
          });
        } else {
          console.error('Clipboard API not supported');
        }
      });
    };
    
    loadAndDrawLayers();
  };

  const tweetGMMessage = () => {
    const tweetText = `GM from Crafty Canines! Craft your own using our fun art generation tool! craft.craftycanines.xyz`;
    const tweetUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(tweetText)}`;
    window.open(tweetUrl, '_blank');
  };

return (
    <div className="App">
      <Header />
      <div className="container">
        {/* Actions panel moved to the top */}
        <ActionsPanel
          randomizeTraits={randomizeTraits}
          resetTraits={resetTraits}
          downloadImage={downloadImage}
          copyToClipboard={copyToClipboard}
          tweetGMMessage={tweetGMMessage}
          handleTokenIDChange={handleTokenIDChange}
          tokenID={tokenID}
          copySuccess={copySuccess}
        />
        <div className="main-content">
		  <ArtDisplay
            layersOrder={baseLayersOrder}
            selectedTraits={selectedTraits}
            artRef={artRef}
            tokenID={tokenID}
            matchType={matchType}
          />
          <TraitSelection
            selectedTraits={selectedTraits}
            handleTraitChange={handleTraitChange}
            setShowRules={setShowRules}
          />
        
        </div>
      </div>
      <Footer />
      {showRules && <RulesModal setShowRules={setShowRules} />}
    </div>
  );
}

export default App;