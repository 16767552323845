import React, { useEffect, useRef, useState } from 'react';
import { getImagePath } from '../utils';

const ArtDisplay = ({ layersOrder, selectedTraits, artRef, tokenID, matchType }) => {
    const canvasRef = useRef(null);
    // On desktop, start with panel open; on mobile, determine based on screen width
    const [isTraitsPanelOpen, setIsTraitsPanelOpen] = useState(window.innerWidth > 768);
    
    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        canvas.width = 280;
        canvas.height = 280;
        // Clear the canvas before redrawing
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        // Sequentially draw layers in order
        const drawLayersSequentially = async () => {
            for (let layer of layersOrder) {
                const trait = selectedTraits[layer];
                if (trait === "None") continue;
                const imagePath = getImagePath(layer, trait, selectedTraits);
                if (!imagePath) continue;
                const image = new Image();
                image.src = imagePath;
                // Wait for image to load before drawing it
                await new Promise((resolve) => {
                    image.onload = () => {
                        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
                        resolve();
                    };
                    image.onerror = () => {
                        console.error(`Failed to load image: ${imagePath}`);
                        resolve();
                    };
                });
            }
        };
        drawLayersSequentially();
    }, [layersOrder, selectedTraits]);
    
    // Filtered and compact display traits
    const displayTraits = Object.entries(selectedTraits)
        .filter(([_, value]) => value !== 'None');
        
    // Toggle the traits panel visibility
    const toggleTraitsPanel = () => {
        setIsTraitsPanelOpen(!isTraitsPanelOpen);
    };

    return (
        <div className="art-display-container">
            <canvas ref={canvasRef} className="art-display" />
            
            {/* Only show match message if there's a match */}
            {tokenID !== null && (
                <div className="match-message">
                    {matchType === 'exact' ? (
                       <>
                        <div className="token-id">
                            Match to token #{tokenID}
                        </div>
                        <a href={`https://magiceden.us/item-details/sei/0xbca0f3c93cd60c09274808baddbcf6bdbeb139c0/${tokenID}`}
                           className="pallet-button"
                           target="_blank"
                           rel="noopener noreferrer">
                            Buy on Magic Eden
                        </a>
                        </>
                    ) : (
                        <div className="token-id">Twin of token #{tokenID}</div>
                    )}
                </div>
            )}
            
            {/* Collapsible traits panel */}
            <div className="selected-traits-container">
                <button 
                    className={`traits-toggle-button ${isTraitsPanelOpen ? 'open' : 'closed'}`}
                    onClick={toggleTraitsPanel}
                    aria-expanded={isTraitsPanelOpen}
                    aria-controls="traits-panel"
                >
                    <span>Selected Traits</span>
                    <span className="toggle-icon">{isTraitsPanelOpen ? '▲' : '▼'}</span>
                </button>
                
                <div 
                    id="traits-panel"
                    className={`traits-panel ${isTraitsPanelOpen ? 'open' : 'closed'}`}
                    aria-hidden={!isTraitsPanelOpen}
                >
                    <div className="traits-list">
                        {displayTraits.map(([type, value]) => (
                            <div key={type} className="trait-item">
                                <strong>{type.charAt(0).toUpperCase() + type.slice(1)}:</strong> 
                                <span>{value}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ArtDisplay;