import React, { useState } from 'react';
import traits, { rules } from '../traits';
import { getImagePath } from '../utils';

const TraitSelection = ({
  selectedTraits,
  handleTraitChange,
  setShowRules,
}) => {
  const [activeTab, setActiveTab] = useState('background');
  
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };
  
  const handleChange = (traitType, value) => {
    if (value !== "None" && rules[value]) {
      Object.keys(selectedTraits).forEach((key) => {
        if (rules[value].includes(selectedTraits[key])) {
          handleTraitChange(key, "None");
        }
      });
    }
    handleTraitChange(traitType, value);
  };
  
  // Get tab label with proper formatting
  const getTabLabel = (traitType) => {
    return traitType === 'funAddOns' ? 'Fun Add Ons' : traitType.charAt(0).toUpperCase() + traitType.slice(1);
  };
  
  // Split tabs into primary and secondary
  const primaryTabs = ['background', 'clothing', 'eyes', 'fur', 'head', 'mouth'];
  const secondaryTabs = ['funAddOns', 'curated'];
  
  const getFilteredTraits = (traitType) => {
    const selectedTraitValues = Object.values(selectedTraits);
    return traits[traitType]?.map((trait) => {
      const isConflicting = selectedTraitValues.some(
        (selectedTrait) =>
          (rules[selectedTrait] && rules[selectedTrait].includes(trait)) ||
          (rules[trait] && rules[trait].includes(selectedTrait))
      );
      return { trait, isConflicting };
    }) || [];
  };
  
  return (
    <div className="trait-selection">   
      <div className="tabs-container">
        <div className="primary-tabs">
          {primaryTabs.map((traitType) => (
            <button
              key={traitType}
              className={`tab-button ${activeTab === traitType ? 'active-tab' : ''}`}
              onClick={() => handleTabChange(traitType)}
              data-trait-type={traitType}
            >
              <span>{getTabLabel(traitType)}</span>
            </button>
          ))}
        </div>
        
        {/* Secondary tabs (second row) */}
        <div className="secondary-tabs">
          {secondaryTabs.map((traitType) => (
            <button
              key={traitType}
              className={`tab-button ${activeTab === traitType ? 'active-tab' : ''}`}
              onClick={() => handleTabChange(traitType)}
              data-trait-type={traitType}
            >
              <span>{getTabLabel(traitType)}</span>
            </button>
          ))}
        </div>
      </div>
      
      <div className="traits-grid">
        {getFilteredTraits(activeTab).map(({ trait, isConflicting }) => (
          <div
            key={trait}
            className={`trait ${selectedTraits[activeTab] === trait ? 'selected' : ''}`}
            onClick={() => !isConflicting && handleChange(activeTab, trait)}
          >
            <div className="trait-content">
              {getImagePath(activeTab, trait, selectedTraits) && (
                <img
                  src={getImagePath(activeTab, trait, selectedTraits)}
                  alt={trait}
                />
              )}
              {isConflicting && (
                <div className="conflict-overlay">
                  <div className="conflict-icon">❌</div>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TraitSelection;