import React from 'react';
import ThemeToggle from './ThemeToggle';
const Header = () => {
  return (
    <div className="header">
      <div className="header-content">
        <a href="https://craftycanines.xyz" target="_blank" rel="noopener noreferrer">
          <div style={{ display: 'flex', alignItems: 'center' }}>
         
            <span>Crafty Canine Art Generator</span>
          </div>
        </a>
      </div>
      <ThemeToggle />
    </div>
  );
};

export default Header;