// src/utils.js
// Common utility functions for the app

/**
 * Get the appropriate GM Mug trait based on fur color
 * @param {string} fur - The fur color
 * @returns {string} - The corresponding GM Mug trait
 */
export const getGMMugTrait = (fur) => {
  switch (fur) {
    case 'Arctic':
      return 'Arctic GM';
    case 'Espresso':
      return 'Espresso GM';
    case 'Frosted':
      return 'Frosted GM';
    case 'Golden':
      return 'Golden GM';
    case 'Midnight':
      return 'Midnight GM';
    case 'Collab - Seiyan':
      return 'Collab - Seiyan GM';
	  
	      case 'Collab - Gonad Skin':
      return 'Collab - Gonad Skin GM';
    default:
      return 'None';
  }
};

/**
 * Get the appropriate Beer trait based on fur color
 * @param {string} fur - The fur color
 * @returns {string} - The corresponding Beer trait
 */
export const getBeerTrait = (fur) => {
  switch (fur) {
    case 'Arctic':
      return 'Arctic Beer';
    case 'Espresso':
      return 'Espresso Beer';
    case 'Frosted':
      return 'Frosted Beer';
    case 'Golden':
      return 'Golden Beer';
    case 'Midnight':
      return 'Midnight Beer';
    case 'Collab - Seiyan':
      return 'Collab - Seiyan Beer';
	case 'Collab - Gonad Skin':
      return 'Collab - Gonad Skin Beer';
    default:
      return 'None';
  }
};

/**
 * Generate the image path for a trait
 * @param {string} traitType - The type of trait
 * @param {string} trait - The trait value
 * @param {object} selectedTraits - All currently selected traits
 * @returns {string|null} - The path to the image or null for 'None'
 */
export const getImagePath = (traitType, trait, selectedTraits) => {
  if (traitType === 'curated' && trait !== 'None') {
    return `/Layers/curated/${trait.replace(/ /g, '%20')}.png`;
  }
  if (traitType === 'funAddOns') {
    if (trait === 'GM Mug') {
      const mugFileName = getGMMugTrait(selectedTraits.fur).replace(/ /g, '%20');
      return `/Layers/paw/${mugFileName}.png`;
    }
    if (trait === 'Beer') {
      const beerFileName = getBeerTrait(selectedTraits.fur).replace(/ /g, '%20');
      return `/Layers/paw/${beerFileName}.png`;
    }
    if (trait !== 'None') {
      return `/Layers/paw/${trait.replace(/ /g, '%20')}.png`;
    }
  }
  if (trait === 'None') {
    return null;
  }
  return `/Layers/${traitType}/${trait.replace(/ /g, '%20')}.png`;
};