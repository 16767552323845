import React from 'react';
import MagicEdenIcon from '../icons/Magic Eden.png';
import DiscordIcon from '../icons/discord.png';
import TwitterIcon from '../icons/twitter.png';
import MediumIcon from '../icons/medium.png';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="links-container">
        <a href="https://magiceden.us/collections/sei/crafty-canine-1" target="_blank" rel="noopener noreferrer" className="btn-link">
          <img src={MagicEdenIcon} alt="Magic Eden" className="social-icon" />
          <span className="link-text">Buy on Magic Eden</span>
        </a>
        <a href="https://discord.com/invite/NRmRcmJV6w" target="_blank" rel="noopener noreferrer" className="btn-link">
          <img src={DiscordIcon} alt="Discord" className="social-icon" />
          <span className="link-text">Discord</span>
        </a>
        <a href="https://x.com/crafty_canines" target="_blank" rel="noopener noreferrer" className="btn-link">
          <img src={TwitterIcon} alt="Twitter" className="social-icon" />
          <span className="link-text">Twitter/X</span>
        </a>
        <a href="https://medium.com/@craftycanines" target="_blank" rel="noopener noreferrer" className="btn-link">
          <img src={MediumIcon} alt="Medium" className="social-icon" />
          <span className="link-text">Medium</span>
        </a>
      </div>
    </footer>
  );
}

export default Footer;