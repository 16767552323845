import React, { useState } from 'react';
import { FaTwitter, FaMugHot, FaDice, FaRedo, FaDownload, FaSearch, FaCopy } from 'react-icons/fa';

const ActionsPanel = ({
  randomizeTraits,
  resetTraits,
  downloadImage,
  copyToClipboard,
  tweetGMMessage,
  handleTokenIDChange,
  tokenID,
  copySuccess
}) => {
  const [tokenInput, setTokenInput] = useState(tokenID || '');

  const handleInputFocus = (event) => {
    event.target.placeholder = "";
  };

  const handleInputBlur = (event) => {
    if (!event.target.value) {
      event.target.placeholder = "###";
    }
  };

  const handleTokenInputChange = (event) => {
    let input = event.target.value;
    
    if (input !== "" && !/^\d+$/.test(input)) {
      return;
    }
    
    if (input !== "") {
      const numericInput = parseInt(input);
      
      if (numericInput > 420) {
        input = "420";
      }
    }
    
    setTokenInput(input);
    
    if (input !== "") {
      const tokenNumber = parseInt(input);
      handleTokenIDChange(tokenNumber);
    } else {
      resetTraits();
    }
  };

const handleReset = () => {
  setTokenInput('');
  resetTraits();
  
  // Find the input element and restore its placeholder
  const inputElement = document.querySelector('.id-search input');
  if (inputElement) {
    inputElement.placeholder = "###";
  }
};

const handleRandomize = () => {
  setTokenInput('');
  randomizeTraits();
  
  // Find the input element and restore its placeholder
  const inputElement = document.querySelector('.id-search input');
  if (inputElement) {
    inputElement.placeholder = "###";
  }
};

  return (
    <div className="actions-container">
      <div className="button-container">
        <div className="actions-row top-row">
<div className="id-search">
  <span className="search-icon"><FaSearch /></span>
  <input 
    type="text"
    value={tokenInput}
    onChange={handleTokenInputChange}
    placeholder="###"
    maxLength="3"
    onFocus={handleInputFocus}
    onBlur={handleInputBlur}
  />
</div>
		  
          <button className="action-button" onClick={handleRandomize}>
            <FaDice /> Randomize
          </button>
          <button className="action-button" onClick={handleReset}>
            <FaRedo /> Reset
          </button>
          <button className="action-button" onClick={copyToClipboard}>
            <FaCopy /> {copySuccess ? 'Copied!' : 'Copy'}
          </button>
          <button className="action-button" onClick={downloadImage}>
            <FaDownload /> Download
          </button>
        </div>
        <div className="actions-row bottom-row">
          <button className="action-button gm-tweet-button" onClick={tweetGMMessage}>
            <FaTwitter /> | <FaMugHot /> GM Tweet
          </button>
        </div>
      </div>
    </div>
  );
};

export default ActionsPanel;